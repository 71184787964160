const categoryData = {
  "es": [
    {
      id: 1,
      title: 'Account Settings',
      icon: 'SettingsIcon',
      iconColor: 'text-primary',
      questions: [
        {
          id: 10,
          question: '¿Cómo cambio mi nombre de usuario?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: '¿Puedo cambiar mi email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: '¿Cómo cambio mi contraseña?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: '¿Cómo cambio el idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: '¿Cómo puedo gestionar mis dispositivos?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: '¿Cómo puedo gestionar mis suscripciones?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
    },
    {
      id: 3,
      title: 'School Groups',
      icon: 'UsersIcon',
      iconColor: 'text-warning',
      questions: [
        {
          id: 30,
          question: '¿Cómo creo un grupo?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: '¿Cómo puedo ver y editar un grupo?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: '¿Cómo puedo borrar los grupos que ya no necesito?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: '¿Cómo asigno profesores a un grupo?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: '¿Cómo añado alumnos a un grupo?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        // {
        //   id: 35,
        //   question: '¿Cómo descargo mi listado actual de grupos?',
        //   slug: 'how-do-i-download-a-list-of-my-groups',
        //   role: ['admin'],
        // },
      ],
    },
    {
      id: 4,
      title: 'School Dreamers',
      icon: 'SmileIcon',
      iconColor: 'text-info',
      questions: [
        {
          id: 41,
          question: '¿Cómo creo un niño?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: '¿Cómo puedo ver y editar un niño?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: '¿Cómo puedo borrar los niños que ya no necesito?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: '¿Cómo asigno grupos a un niño?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: '¿Cómo asigno un correo electronico a un niño?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
      ],
    },
    {
      id: 5,
      title: 'School Structure',
      icon: 'HomeIcon',
      iconColor: 'text-warning',
      questions: [
        {
          id: 51,
          question: '¿Cómo elimino la estructura de mi colegio?',
          slug: 'how-do-i-delete-school-structure',
          role: ['admin'],
        },
      ],
    }
  ],
  "en": [
    {
      id: 1,
      title: "Account Settings",
      icon: "SettingsIcon",
      iconColor: "text-primary",
      questions: [
        {
          id: 10,
          question: "How do I change my username?",
          slug: "how-do-i-change-my-username",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 11,
          question: "Can I change my email?",
          slug: "can-i-change-my-email",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 12,
          question: "How do I change my password?",
          slug: "how-do-i-change-my-password",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 13,
          question: "How do I change my language?",
          slug: "how-do-i-change-my-language",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 14,
          question: "How do I handle my devices?",
          slug: "how-do-i-handle-my-devices",
          role: ["admin", "user", "school-admin", "teacher", "support"]
        },
        {
          id: 15,
          question: "How do I handle my subscriptions?",
          slug: "how-do-i-handle-my-subscriptions",
          role: ["admin", "user", "school-admin", "teacher", "support"]
        }
      ]
    },
    {
      id: 3,
      title: "School Groups",
      icon: "UsersIcon",
      iconColor: "text-warning",
      questions: [
        {
          id: 30,
          question: "How do I create a group?",
          slug: "how-do-i-create-a-group",
          role: ["admin", "user", "partner", "school-admin", "support"]
        },
        {
          id: 31,
          question: "How can I see and edit a group?",
          slug: "how-can-i-see-and-edit-a-group",
          role: ["admin", "user", "partner", "school-admin", "support"]
        },
        {
          id: 32,
          question: "How can I delete the groups I no longer need?",
          slug: "how-can-i-delete-the-groups-i-no-longer-need",
          role: ["admin", "user", "partner", "school-admin", "support"]
        },
        {
          id: 33,
          question: "How do I assign teachers to a group?",
          slug: "how-do-i-assign-teachers-to-a-group",
          role: ["admin", "partner", "school-admin", "support"]
        },
        {
          id: 34,
          question: "How do I assign students to a group?",
          slug: "how-do-i-assign-students-to-a-group",
          role: ["admin", "partner", "school-admin", "support"]
        },
        // {
        //   id: 35,
        //   question: "How do I download a list of my groups?",
        //   slug: "how-do-i-download-a-list-of-my-groups",
        //   role: ["admin"]
        // },
      ]
    },
    {
      id: 4,
      title: "School Dreamers",
      icon: "SmileIcon",
      iconColor: "text-info",
      questions: [
        {
          id: 41,
          question: "How do I create a dreamer?",
          slug: "how-do-i-create-a-dreamer",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 42,
          question: "How can I see and edit a dreamer?",
          slug: "how-can-i-see-and-edit-a-dreamer",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 43,
          question: "How can I delete the dreamers I no longer need?",
          slug: "how-can-i-delete-the-dreamers-i-no-longer-need",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 44,
          question: "How do I assign groups to a dreamer?",
          slug: "how-do-i-assign-groups-to-a-dreamer",
          role: ["admin", "user", "partner", "school-admin", "support"]
        },
        {
          id: 45,
          question: "How do I assign an email to a dreamer?",
          slug: "how-do-i-assign-an-email-to-a-dreamer",
          role: ["admin", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 46,
          question: "How do I download a list of my dreamers?",
          slug: "how-do-i-download-a-list-of-my-dreamers",
          role: ["admin"]
        }
      ]
    },
    {
      id: 5,
      title: "School Structure",
      icon: "HomeIcon",
      iconColor: "text-warning",
      questions: [
        {
          id: 51,
          question: "How do I delete school structure?",
          slug: "how-do-i-delete-school-structure",
          role: ["admin"]
        }
      ]
    }
  ],
  "ca": [
    {
      id: 1,
      title: "Account Settings",
      icon: "SettingsIcon",
      iconColor: "text-primary",
      questions: [
        {
          id: 10,
          question: "Com puc canviar el meu nom d'usuari?",
          slug: "how-do-i-change-my-username",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 11,
          question: "Puc canviar el meu correu electrònic?",
          slug: "can-i-change-my-email",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 12,
          question: "Com puc canviar la meva contrasenya?",
          slug: "how-do-i-change-my-password",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 13,
          question: "Com puc canviar l'idioma?",
          slug: "how-do-i-change-my-language",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 14,
          question: "Com puc gestionar els meus dispositius?",
          slug: "how-do-i-handle-my-devices",
          role: ["admin", "user", "school-admin", "teacher", "support"]
        },
        {
          id: 15,
          question: "Com puc gestionar les meves subscripcions?",
          slug: "how-do-i-handle-my-subscriptions",
          role: ["admin", "user", "school-admin", "teacher", "support"]
        }
      ]
    },
    {
      id: 3,
      title: "School Groups",
      icon: "UsersIcon",
      iconColor: "text-warning",
      questions: [
        {
          id: 30,
          question: "Com puc crear un grup?",
          slug: "how-do-i-create-a-group",
          role: ["admin", "user", "partner", "school-admin", "support"]
        },
        {
          id: 31,
          question: "Com puc veure i editar un grup?",
          slug: "how-can-i-see-and-edit-a-group",
          role: ["admin", "user", "partner", "school-admin", "support"]
        },
        {
          id: 32,
          question: "Com puc suprimir els grups que ja no necessito?",
          slug: "how-can-i-delete-the-groups-i-no-longer-need",
          role: ["admin", "user", "partner", "school-admin", "support"]
        },
        {
          id: 33,
          question: "Com puc assignar professors a un grup?",
          slug: "how-do-i-assign-teachers-to-a-group",
          role: ["admin", "partner", "school-admin", "support"]
        },
        {
          id: 34,
          question: "Com puc assignar alumnes a un grup?",
          slug: "how-do-i-assign-students-to-a-group",
          role: ["admin", "partner", "school-admin", "support"]
        },
        // {
        //   id: 35,
        //   question: "Com puc descarregar el meu llistat actual de grups?",
        //   slug: "how-do-i-download-a-list-of-my-groups",
        //   role: ["admin"]
        // },
      ]
    },
    {
      id: 4,
      title: "School Dreamers",
      icon: "SmileIcon",
      iconColor: "text-info",
      questions: [
        {
          id: 41,
          question: "Com puc crear un nen?",
          slug: "how-do-i-create-a-dreamer",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 42,
          question: "Com puc veure i editar un nen?",
          slug: "how-can-i-see-and-edit-a-dreamer",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 43,
          question: "Com puc suprimir els nens que ja no necessito?",
          slug: "how-can-i-delete-the-dreamers-i-no-longer-need",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 44,
          question: "Com puc assignar grups a un nen?",
          slug: "how-do-i-assign-groups-to-a-dreamer",
          role: ["admin", "user", "partner", "school-admin", "support"]
        },
        {
          id: 45,
          question: "Com puc assignar un correu electrònic a un nen?",
          slug: "how-do-i-assign-an-email-to-a-dreamer",
          role: ["admin", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 46,
          question: "Com puc descarregar el meu llistat actual de nens?",
          slug: "how-do-i-download-a-list-of-my-dreamers",
          role: ["admin"]
        }
      ]
    },
    {
      id: 5,
      title: "School Structure",
      icon: "HomeIcon",
      iconColor: "text-warning",
      questions: [
        {
          id: 51,
          question: "Com puc suprimir l'estructura de la meva escola?",
          slug: "how-do-i-delete-school-structure",
          role: ["admin"]
        }
      ]
    }
  ],
  "fr": [
    {
      id: 1,
      title: "Account Settings",
      icon: "SettingsIcon",
      iconColor: "text-primary",
      questions: [
        {
          id: 10,
          question: "Comment puis-je changer mon nom d'utilisateur?",
          slug: "how-do-i-change-my-username",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 11,
          question: "Puis-je changer mon email?",
          slug: "can-i-change-my-email",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 12,
          question: "Comment puis-je changer mon mot de passe?",
          slug: "how-do-i-change-my-password",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 13,
          question: "Comment puis-je changer la langue?",
          slug: "how-do-i-change-my-language",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 14,
          question: "Comment puis-je gérer mes appareils?",
          slug: "how-do-i-handle-my-devices",
          role: ["admin", "user", "school-admin", "teacher", "support"]
        },
        {
          id: 15,
          question: "Comment puis-je gérer mes abonnements?",
          slug: "how-do-i-handle-my-subscriptions",
          role: ["admin", "user", "school-admin", "teacher", "support"]
        }
      ]
    },
    {
      id: 3,
      title: "School Groups",
      icon: "UsersIcon",
      iconColor: "text-warning",
      questions: [
        {
          id: 30,
          question: "Comment puis-je créer un groupe?",
          slug: "how-do-i-create-a-group",
          role: ["admin", "user", "partner", "school-admin", "support"]
        },
        {
          id: 31,
          question: "Comment puis-je voir et éditer un groupe?",
          slug: "how-can-i-see-and-edit-a-group",
          role: ["admin", "user", "partner", "school-admin", "support"]
        },
        {
          id: 32,
          question: "Comment puis-je supprimer les groupes dont je n'ai plus besoin?",
          slug: "how-can-i-delete-the-groups-i-no-longer-need",
          role: ["admin", "user", "partner", "school-admin", "support"]
        },
        {
          id: 33,
          question: "Comment puis-je attribuer des enseignants à un groupe?",
          slug: "how-do-i-assign-teachers-to-a-group",
          role: ["admin", "partner", "school-admin", "support"]
        },
        {
          id: 34,
          question: "Comment puis-je attribuer des élèves à un groupe?",
          slug: "how-do-i-assign-students-to-a-group",
          role: ["admin", "partner", "school-admin", "support"]
        },
        // {
        //   id: 35,
        //   question: "Comment puis-je télécharger la liste de mes groupes actuels?",
        //   slug: "how-do-i-download-a-list-of-my-groups",
        //   role: ["admin"]
        // },
      ]
    },
    {
      id: 4,
      title: "School Dreamers",
      icon: "SmileIcon",
      iconColor: "text-info",
      questions: [
        {
          id: 41,
          question: "Comment puis-je créer un rêveur?",
          slug: "how-do-i-create-a-dreamer",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 42,
          question: "Comment puis-je voir et éditer un rêveur?",
          slug: "how-can-i-see-and-edit-a-dreamer",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 43,
          question: "Comment puis-je supprimer les rêveurs dont je n'ai plus besoin?",
          slug: "how-can-i-delete-the-dreamers-i-no-longer-need",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 44,
          question: "Comment puis-je attribuer des groupes à un rêveur?",
          slug: "how-do-i-assign-groups-to-a-dreamer",
          role: ["admin", "user", "partner", "school-admin", "support"]
        },
        {
          id: 45,
          question: "Comment puis-je attribuer un e-mail à un rêveur?",
          slug: "how-do-i-assign-an-email-to-a-dreamer",
          role: ["admin", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 46,
          question: "Comment puis-je télécharger la liste de mes rêveurs actuels?",
          slug: "how-do-i-download-a-list-of-my-dreamers",
          role: ["admin"]
        }
      ]
    },
    {
      id: 5,
      title: "School Structure",
      icon: "HomeIcon",
      iconColor: "text-warning",
      questions: [
        {
          id: 51,
          question: "Comment puis-je supprimer la structure de mon école?",
          slug: "how-do-i-delete-school-structure",
          role: ["admin"]
        }
      ]
    }
  ],
  "pt": [
    {
      id: 1,
      title: "Account Settings",
      icon: "SettingsIcon",
      iconColor: "text-primary",
      questions: [
        {
          id: 10,
          question: "Como eu posso mudar meu nome de usuário?",
          slug: "how-do-i-change-my-username",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 11,
          question: "Posso mudar meu email?",
          slug: "can-i-change-my-email",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 12,
          question: "Como eu posso mudar minha senha?",
          slug: "how-do-i-change-my-password",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 13,
          question: "Como eu posso mudar o idioma?",
          slug: "how-do-i-change-my-language",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 14,
          question: "Como eu posso gerenciar meus dispositivos?",
          slug: "how-do-i-handle-my-devices",
          role: ["admin", "user", "school-admin", "teacher", "support"]
        },
        {
          id: 15,
          question: "Como eu posso gerenciar minhas assinaturas?",
          slug: "how-do-i-handle-my-subscriptions",
          role: ["admin", "user", "school-admin", "teacher", "support"]
        }
      ]
    },
    {
      id: 3,
      title: "School Groups",
      icon: "UsersIcon",
      iconColor: "text-warning",
      questions: [
        {
          id: 30,
          question: "Como eu posso criar um grupo?",
          slug: "how-do-i-create-a-group",
          role: ["admin", "user", "partner", "school-admin", "support"]
        },
        {
          id: 31,
          question: "Como eu posso ver e editar um grupo?",
          slug: "how-can-i-see-and-edit-a-group",
          role: ["admin", "user", "partner", "school-admin", "support"]
        },
        {
          id: 32,
          question: "Como eu posso excluir os grupos que não preciso mais?",
          slug: "how-can-i-delete-the-groups-i-no-longer-need",
          role: ["admin", "user", "partner", "school-admin", "support"]
        },
        {
          id: 33,
          question: "Como eu posso atribuir professores a um grupo?",
          slug: "how-do-i-assign-teachers-to-a-group",
          role: ["admin", "partner", "school-admin", "support"]
        },
        {
          id: 34,
          question: "Como eu posso atribuir alunos a um grupo?",
          slug: "how-do-i-assign-students-to-a-group",
          role: ["admin", "partner", "school-admin", "support"]
        },
        // {
        //   id: 35,
        //   question: "Como eu posso baixar uma lista dos meus grupos?",
        //   slug: "how-do-i-download-a-list-of-my-groups",
        //   role: ["admin"]
        // },
      ]
    },
    {
      id: 4,
      title: "School Dreamers",
      icon: "SmileIcon",
      iconColor: "text-info",
      questions: [
        {
          id: 41,
          question: "Como eu posso criar um sonhador?",
          slug: "how-do-i-create-a-dreamer",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 42,
          question: "Como eu posso ver e editar um sonhador?",
          slug: "how-can-i-see-and-edit-a-dreamer",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 43,
          question: "Como eu posso excluir os sonhadores que não preciso mais?",
          slug: "how-can-i-delete-the-dreamers-i-no-longer-need",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 44,
          question: "Como eu posso atribuir grupos a um sonhador?",
          slug: "how-do-i-assign-groups-to-a-dreamer",
          role: ["admin", "user", "partner", "school-admin", "support"]
        },
        {
          id: 45,
          question: "Como eu posso atribuir um e-mail a um sonhador?",
          slug: "how-do-i-assign-an-email-to-a-dreamer",
          role: ["admin", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 46,
          question: "Como eu posso baixar uma lista dos meus sonhadores?",
          slug: "how-do-i-download-a-list-of-my-dreamers",
          role: ["admin"]
        }
      ]
    },
    {
      id: 5,
      title: "School Structure",
      icon: "HomeIcon",
      iconColor: "text-warning",
      questions: [
        {
          id: 51,
          question: "Como eu posso excluir a estrutura da minha escola?",
          slug: "how-do-i-delete-school-structure",
          role: ["admin"]
        }
      ]
    }
  ],
  "it": [
    {
      id: 1,
      title: "Account Settings",
      icon: "SettingsIcon",
      iconColor: "text-primary",
      questions: [
        {
          id: 10,
          question: "Come cambio il mio nome utente?",
          slug: "how-do-i-change-my-username",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 11,
          question: "Posso cambiare la mia email?",
          slug: "can-i-change-my-email",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 12,
          question: "Come cambio la mia password?",
          slug: "how-do-i-change-my-password",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 13,
          question: "Come cambio la lingua?",
          slug: "how-do-i-change-my-language",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 14,
          question: "Come posso gestire i miei dispositivi?",
          slug: "how-do-i-handle-my-devices",
          role: ["admin", "user", "school-admin", "teacher", "support"]
        },
        {
          id: 15,
          question: "Come posso gestire le mie iscrizioni?",
          slug: "how-do-i-handle-my-subscriptions",
          role: ["admin", "user", "school-admin", "teacher", "support"]
        }
      ]
    },
    {
      id: 3,
      title: "School Groups",
      icon: "UsersIcon",
      iconColor: "text-warning",
      questions: [
        {
          id: 30,
          question: "Come creo un gruppo?",
          slug: "how-do-i-create-a-group",
          role: ["admin", "user", "partner", "school-admin", "support"]
        },
        {
          id: 31,
          question: "Come posso vedere e modificare un gruppo?",
          slug: "how-can-i-see-and-edit-a-group",
          role: ["admin", "user", "partner", "school-admin", "support"]
        },
        {
          id: 32,
          question: "Come posso eliminare i gruppi che non mi servono più?",
          slug: "how-can-i-delete-the-groups-i-no-longer-need",
          role: ["admin", "user", "partner", "school-admin", "support"]
        },
        {
          id: 33,
          question: "Come assegno insegnanti a un gruppo?",
          slug: "how-do-i-assign-teachers-to-a-group",
          role: ["admin", "partner", "school-admin", "support"]
        },
        {
          id: 34,
          question: "Come aggiungo studenti a un gruppo?",
          slug: "how-do-i-assign-students-to-a-group",
          role: ["admin", "partner", "school-admin", "support"]
        },
        // {
        //   id: 35,
        //   question: "Come scarico un elenco dei miei gruppi?",
        //   slug: "how-do-i-download-a-list-of-my-groups",
        //   role: ["admin"]
        // },
      ]
    },
    {
      id: 4,
      title: "School Dreamers",
      icon: "SmileIcon",
      iconColor: "text-info",
      questions: [
        {
          id: 41,
          question: "Come creo un bambino?",
          slug: "how-do-i-create-a-dreamer",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 42,
          question: "Come posso vedere e modificare un bambino?",
          slug: "how-can-i-see-and-edit-a-dreamer",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 43,
          question: "Come posso eliminare i bambini che non mi servono più?",
          slug: "how-can-i-delete-the-dreamers-i-no-longer-need",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 44,
          question: "Come assegno gruppi a un bambino?",
          slug: "how-do-i-assign-groups-to-a-dreamer",
          role: ["admin", "user", "partner", "school-admin", "support"]
        },
        {
          id: 45,
          question: "Come assegno un indirizzo email a un bambino?",
          slug: "how-do-i-assign-an-email-to-a-dreamer",
          role: ["admin", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 46,
          question: "Come scarico un elenco dei miei bambini?",
          slug: "how-do-i-download-a-list-of-my-dreamers",
          role: ["admin"]
        }
      ]
    },
    {
      id: 5,
      title: "School Structure",
      icon: "HomeIcon",
      iconColor: "text-warning",
      questions: [
        {
          id: 51,
          question: "Come elimino la struttura della mia scuola?",
          slug: "how-do-i-delete-school-structure",
          role: ["admin"]
        }
      ]
    }
  ],
  "la": [
    {
      id: 1,
      title: 'Account Settings',
      icon: 'SettingsIcon',
      iconColor: 'text-primary',
      questions: [
        {
          id: 10,
          question: '¿Cómo cambio mi nombre de usuario?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: '¿Puedo cambiar mi email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: '¿Cómo cambio mi contraseña?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: '¿Cómo cambio el idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: '¿Cómo puedo gestionar mis dispositivos?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: '¿Cómo puedo gestionar mis suscripciones?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
    },
    {
      id: 3,
      title: 'School Groups',
      icon: 'UsersIcon',
      iconColor: 'text-warning',
      questions: [
        {
          id: 30,
          question: '¿Cómo creo un grupo?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: '¿Cómo puedo ver y editar un grupo?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: '¿Cómo puedo borrar los grupos que ya no necesito?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: '¿Cómo asigno profesores a un grupo?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: '¿Cómo añado alumnos a un grupo?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        // {
        //   id: 35,
        //   question: '¿Cómo descargo mi listado actual de grupos?',
        //   slug: 'how-do-i-download-a-list-of-my-groups',
        //   role: ['admin'],
        // },
      ],
    },
    {
      id: 4,
      title: 'School Dreamers',
      icon: 'SmileIcon',
      iconColor: 'text-info',
      questions: [
        {
          id: 41,
          question: '¿Cómo creo un niño?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: '¿Cómo puedo ver y editar un niño?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: '¿Cómo puedo borrar los niños que ya no necesito?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: '¿Cómo asigno grupos a un niño?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: '¿Cómo asigno un correo electronico a un niño?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
      ],
    },
    {
      id: 5,
      title: 'School Structure',
      icon: 'HomeIcon',
      iconColor: 'text-warning',
      questions: [
        {
          id: 51,
          question: '¿Cómo elimino la estructura de mi colegio?',
          slug: 'how-do-i-delete-school-structure',
          role: ['admin'],
        },
      ],
    }
  ],
  "tr": [
    {
      id: 1,
      title: "Account Settings",
      icon: "SettingsIcon",
      iconColor: "text-primary",
      questions: [
        {
          id: 10,
          question: "How do I change my username?",
          slug: "how-do-i-change-my-username",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 11,
          question: "Can I change my email?",
          slug: "can-i-change-my-email",
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: "How do I change my password?",
          slug: "how-do-i-change-my-password",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 13,
          question: "How do I change my language?",
          slug: "how-do-i-change-my-language",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 14,
          question: "How do I handle my devices?",
          slug: "how-do-i-handle-my-devices",
          role: ["admin", "user", "school-admin", "teacher", "support"]
        },
        {
          id: 15,
          question: "How do I handle my subscriptions?",
          slug: "how-do-i-handle-my-subscriptions",
          role: ["admin", "user", "school-admin", "teacher", "support"]
        }
      ]
    },
    {
      id: 3,
      title: "School Groups",
      icon: "UsersIcon",
      iconColor: "text-warning",
      questions: [
        {
          id: 30,
          question: "How do I create a group?",
          slug: "how-do-i-create-a-group",
          role: ["admin", "user", "partner", "school-admin", "support"]
        },
        {
          id: 31,
          question: "How can I see and edit a group?",
          slug: "how-can-i-see-and-edit-a-group",
          role: ["admin", "user", "partner", "school-admin", "support"]
        },
        {
          id: 32,
          question: "How can I delete the groups I no longer need?",
          slug: "how-can-i-delete-the-groups-i-no-longer-need",
          role: ["admin", "user", "partner", "school-admin", "support"]
        },
        {
          id: 33,
          question: "How do I assign teachers to a group?",
          slug: "how-do-i-assign-teachers-to-a-group",
          role: ["admin", "partner", "school-admin", "support"]
        },
        {
          id: 34,
          question: "How do I assign students to a group?",
          slug: "how-do-i-assign-students-to-a-group",
          role: ["admin", "partner", "school-admin", "support"]
        },
        // {
        //   id: 35,
        //   question: "How do I download a list of my groups?",
        //   slug: "how-do-i-download-a-list-of-my-groups",
        //   role: ["admin"]
        // },
      ]
    },
    {
      id: 4,
      title: "School Dreamers",
      icon: "SmileIcon",
      iconColor: "text-info",
      questions: [
        {
          id: 41,
          question: "How do I create a dreamer?",
          slug: "how-do-i-create-a-dreamer",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 42,
          question: "How can I see and edit a dreamer?",
          slug: "how-can-i-see-and-edit-a-dreamer",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 43,
          question: "How can I delete the dreamers I no longer need?",
          slug: "how-can-i-delete-the-dreamers-i-no-longer-need",
          role: ["admin", "user", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 44,
          question: "How do I assign groups to a dreamer?",
          slug: "how-do-i-assign-groups-to-a-dreamer",
          role: ["admin", "user", "partner", "school-admin", "support"]
        },
        {
          id: 45,
          question: "How do I assign an email to a dreamer?",
          slug: "how-do-i-assign-an-email-to-a-dreamer",
          role: ["admin", "partner", "school-admin", "teacher", "support"]
        },
        {
          id: 46,
          question: "How do I download a list of my dreamers?",
          slug: "how-do-i-download-a-list-of-my-dreamers",
          role: ["admin"]
        }
      ]
    },
    {
      id: 5,
      title: "School Structure",
      icon: "HomeIcon",
      iconColor: "text-warning",
      questions: [
        {
          id: 51,
          question: "How do I delete school structure?",
          slug: "how-do-i-delete-school-structure",
          role: ["admin"]
        }
      ]
    }
  ],

}

  

export default categoryData